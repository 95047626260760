import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Account, Reflection } from '../../../../../store/Models';
import { makeDateHeaderRussian } from '../../../../../utils';
import ReflectionComponent from '../../../../Reflection';

interface InfiniteScrollListProps {
    items: Reflection[];
    currentUser: Account;
}

const InfiniteScrollList: React.FC<InfiniteScrollListProps> = ({ items, currentUser }) => {
    console.log('enter component');
    const batchSize = 100;

    const selectBatch = (start: number, end: number): Reflection[] => {
        console.log('select batch from: ' + start + ' to: ' + end);
        return items.slice(start, end);
    }

    const [displayItems, setDisplayItems] = React.useState<Reflection[]>(selectBatch(0, Math.min(batchSize, items.length)));
    const [hasMore, setHasMore] = React.useState(false);

    const loadMore = () => {
        
        const nextIndex = Math.min(displayItems.length + batchSize, items.length);
        console.log('load more from: ' + displayItems.length + ' to: ' + nextIndex);
        setDisplayItems(x => x.concat(selectBatch(displayItems.length, nextIndex)));
        if (nextIndex == items.length)
            setHasMore(false);
        else
            setHasMore(true);
    }

    const containerRef = React.useRef<HTMLDivElement>(null);

    const isFirstOnItsDateInLocalTime = (item: Reflection): boolean => {
        // Extract the target date from the target object
        let targetDateCopy = new Date(item.post.publishDateTime);
        targetDateCopy.setHours(targetDateCopy.getHours() - targetDateCopy.getTimezoneOffset() / 60); // to local time!
        const targetDate = new Date(targetDateCopy);

        // Filter all objects that share the same date as the target object
        const sameDateObjects = items.filter(r => {
            let dateCopy = new Date(r.post.publishDateTime);
            dateCopy.setHours(dateCopy.getHours() - dateCopy.getTimezoneOffset() / 60); // to local time!
            const objDate = new Date(dateCopy);
            return objDate.toDateString() === targetDate.toDateString();
        });

        // Find the object with the minimal date and time among the same date objects
        const minDateObject = sameDateObjects.reduce((minObj, obj) => {
            const objDate = new Date(obj.post.publishDateTime);
            return objDate < new Date(minObj.post.publishDateTime) ? obj : minObj;
        }, sameDateObjects[0]);

        // Check if the target object is the one with the minimal date
        return item === minDateObject;
    }

    /*
    React.useEffect(() => {
        if (containerRef.current != null) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [items.length]);
    */

    return (
        <div ref={containerRef} style={{ height: '100vh', overflow: 'auto' }}> {/* Full-height container */}
            <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                useWindow={false} // Use the container as the scrollable element
            >
                {displayItems.map((item) => (
                    <div className="mt-1 mb-1" data-id={item.id}
                        data-my={item.post.publishAccount.id === currentUser.id}>
                        {isFirstOnItsDateInLocalTime(item) && <div className="date-header">
                            <span className="date-header-span-reflections">{makeDateHeaderRussian(item.post.publishDateTime.toString())}</span></div>}
                        <ReflectionComponent currentUser={currentUser} reflection={item}
                            isFirst={false}
                            isSecond={false}
                            isExpanded={false}
                            onContextMenu={() => { }}
                            onTouchMove={() => { }}
                            onTouchStop={() => { }}
                            onGoToReply={() => { }}
                            onAccountClick={() => { }} //to do implement
                            onExpand={() => { }}
                        />
                    </div>
                ))}
            </InfiniteScroll>
        </div>
    );
};

export default InfiniteScrollList;
