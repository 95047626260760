import * as React from 'react';
import './custom.css';
import { useLoading } from './context/LoadingContext';
import MainLayout from './components/telegramApp/layout/MainLayout';

function App() {
    const { loading } = useLoading();
    /*
    return (
        <BrowserRouter>
            {loading && <Loader />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/tg" element={<TelegramAuthModule />} />
                <Route path="/reflection-app" element={<ReflectionModule />} />
            </Routes>
        </BrowserRouter>
    );
    */
    return (
        <MainLayout productionMode={true} />
        )
}
export default App;