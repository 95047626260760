import * as React from 'react';
import { Account } from '../../../../../store/Models';
import { formatCost } from '../../../../../utils';

interface StepperRenewal1Props {
    currentUser: Account;
    onBack: () => void;
    onNext: (selectedOption: any) => void;
}

const StepperRenewal1: React.FC<StepperRenewal1Props> = ({ currentUser, onBack, onNext }) => {
    const subscriptionOptions = [
        { DisplayName: 'Апгрейд на Базовый', Name: 'Базовый', SubscriptionCode: 'BASIC_INITIAL', Class: 10, Cost: 100000, RubCost: 20000, SignatureValue: '3aa2f6de1ae6a69bcb2f40102f8eaa86' },
        { DisplayName: 'Апгрейд на Стандарт', Name: 'Стандарт', SubscriptionCode: 'STANDARD_INITIAL', Class: 20, Cost: 480000, RubCost: 96000, SignatureValue: '57827b63ad4f051085fa81c912acccf5' },
        { DisplayName: 'Апгрейд на Бизнес', Name: 'Бизнес', SubscriptionCode: 'BUSINESS_INITIAL', Class: 30, Cost: 2400000, RubCost: 480000, SignatureValue: 'e05d469e3d66926208a10bd394628a5b' },

        { DisplayName: 'Продлить Эксклюзив', Name: 'Эксклюзив', SubscriptionCode: 'EXCLUSIVE_RENEWAL', Class: 5, Cost: 10000, RubCost: 2000, SignatureValue: 'a4ace0e1ee5024846957091c60639619' },
        { DisplayName: 'Продлить Базовый', Name: 'Базовый', SubscriptionCode: 'BASIC_RENEWAL', Class: 10, Cost: 10000, RubCost: 2000, SignatureValue: 'a4ace0e1ee5024846957091c60639619' },
        { DisplayName: 'Продлить Стандарт', Name: 'Стандарт', SubscriptionCode: 'STANDARD_RENEWAL', Class: 20, Cost: 20000, RubCost: 4000, SignatureValue: '2464d2d74adc07840c7074e38d5f33bb' },
        { DisplayName: 'Продлить Бизнес', Name: 'Бизнес', SubscriptionCode: 'BUSINESS_RENEWAL', Class: 30, Cost: 50000, RubCost: 10000, SignatureValue: 'f4f011c47f050a7172b3523a7eb6f7c2' },
        { DisplayName: 'Продлить Бизнес Плюс', Name: 'Бизнес Плюс', SubscriptionCode: 'BUSINESS_PLUS_RENEWAL', Class: 40, Cost: 500000, RubCost: 100000, SignatureValue: '4cacafd9a1b2b669e51ea924640ee62a' }
    ].filter(x => x.Class > currentUser.class && x.SubscriptionCode.endsWith('INITIAL') || x.Class == currentUser.class && x.SubscriptionCode.endsWith('RENEWAL'));

    const handleSubscriptionSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        const selected = subscriptionOptions.find(sub => sub.SubscriptionCode === selectedValue);
        setSelectedSubscription(selected);
    };

    const [selectedSubscription, setSelectedSubscription] = React.useState<any>(subscriptionOptions.find(sub => sub.Class === currentUser.class));

    return (
        <React.Fragment>
            <div className="stepper-empty-space">
                <div className="stepper-select-caption">Выберите тариф</div>
                <div className="stepper-select-wrapper">
                    <select className="stepper-select" onChange={handleSubscriptionSelect}>
                        {subscriptionOptions.map((option) => (
                            <option key={option.SubscriptionCode} value={option.SubscriptionCode} selected={option.Class == currentUser.class}>
                                {option.DisplayName} (₸{formatCost(option.Cost)})
                            </option>
                        ))}
                    </select>
                </div>
                <div className="stepper-info-text">
                    Сумма: {selectedSubscription ? "₸" + formatCost(selectedSubscription.Cost) : '—'}
                </div>
            </div>
            <div className="stepper-button-container">
                {(currentUser.daysLeft != null && currentUser.daysLeft >= 0) && <button className="stepper-small-button" onClick={onBack}><i className="fas fa-chevron-left" /></button>}
                <button className="stepper-button" onClick={() => onNext(selectedSubscription)} disabled={!selectedSubscription}>
                    К оплате
                </button>
            </div>
        </React.Fragment>
    )
};

export default StepperRenewal1;
