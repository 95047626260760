import * as React from 'react';

interface Stepper3Props {
    paymentMethod: string;
    subscriptionCode: string;
    onBack: () => void;
    onNext: () => void;
}

interface PaymentMethodLink {
    BUSINESS_INITIAL: string;
    STANDARD_INITIAL: string;
    BASIC_INITIAL: string;
    EXCLUSIVE_INITIAL: string;
}

interface ServiceWALink {
    Kaspi: PaymentMethodLink;
    VisaMC: PaymentMethodLink;
    Sberbank: PaymentMethodLink;
}

const Stepper3: React.FC<Stepper3Props> = ({ paymentMethod, subscriptionCode, onBack, onNext }) => {
    const delay = 90;

    const [canFinishPayment, setCanFinishPayment] = React.useState<boolean>(false);

    const serviceWALinks: ServiceWALink = {
        "Kaspi": {
            "BUSINESS_INITIAL": "Здравствуйте! Я оплатил(-а) вступительный взнос в Клуб MillionERA на тариф «Бизнес» стоимостью ₸2,400,000 через Kaspi. Квитанцию прилагаю.",
            "STANDARD_INITIAL": "Здравствуйте! Я оплатил(-а) вступительный взнос в Клуб MillionERA на тариф «Стандарт» стоимостью ₸480,000 через Kaspi. Квитанцию прилагаю.",
            "BASIC_INITIAL": "Здравствуйте! Я оплатил(-а) вступительный взнос в Клуб MillionERA на тариф «Базовый» стоимостью ₸100,000 через Kaspi. Квитанцию прилагаю.",
            "EXCLUSIVE_INITIAL": "Здравствуйте! Я оплатил(-а) вступительный взнос в Клуб MillionERA на тариф «Эксклюзив» стоимостью ₸19,990 через Kaspi. Квитанцию прилагаю.",
        },
        "VisaMC": {
            "BUSINESS_INITIAL": "Здравствуйте! Я оплатил(-а) вступительный взнос в Клуб MillionERA на тариф «Бизнес» стоимостью ₸2,400,000 картой Visa/MC. Квитанцию прилагаю.",
            "STANDARD_INITIAL": "Здравствуйте! Я оплатил(-а) вступительный взнос в Клуб MillionERA на тариф «Стандарт» стоимостью ₸480,000 картой Visa/MC. Квитанцию прилагаю.",
            "BASIC_INITIAL": "Здравствуйте! Я оплатил(-а) вступительный взнос в Клуб MillionERA на тариф «Базовый» стоимостью ₸100,000 картой Visa/MC. Квитанцию прилагаю.",
            "EXCLUSIVE_INITIAL": "Здравствуйте! Я оплатил(-а) вступительный взнос в Клуб MillionERA на тариф «Эксклюзив» стоимостью ₸19,990 картой Visa/MC. Квитанцию прилагаю."
        },
        "Sberbank": {
            "BUSINESS_INITIAL": "Здравствуйте! Я оплатил(-а) вступительный взнос в Клуб MillionERA на тариф «Бизнес» стоимостью ₽480,000 переводом на Сбербанк России. Квитанцию прилагаю.",
            "STANDARD_INITIAL": "Здравствуйте! Я оплатил(-а) вступительный взнос в Клуб MillionERA на тариф «Стандарт» стоимостью ₽96,000 переводом на Сбербанк России. Квитанцию прилагаю.",
            "BASIC_INITIAL": "Здравствуйте! Я оплатил(-а) вступительный взнос в Клуб MillionERA на тариф «Базовый» стоимостью ₽20,000 переводом на Сбербанк России. Квитанцию прилагаю.",
            "EXCLUSIVE_INITIAL": "Здравствуйте! Я оплатил(-а) вступительный взнос в Клуб MillionERA на тариф «Эксклюзив» стоимостью ₽3,990 переводом на Сбербанк России. Квитанцию прилагаю."
        }
    }

    const getServiceWALink = (paymentMethod: string, subscriptionCode: string): string => {
        return "https://wa.me/77052042585?text=" + serviceWALinks[paymentMethod as keyof ServiceWALink][subscriptionCode as keyof PaymentMethodLink];
    }

    return (
        <React.Fragment>
            <div className="stepper-empty-space">
                <div className="stepper-info-text">Отправьте квитанцию</div>
                <div>в <a target="_blank" href={getServiceWALink(paymentMethod, subscriptionCode)} onClick={() => {
                    window.setTimeout(() => setCanFinishPayment(true), delay * 1000);
                }} >Службу Заботы</a></div>
            </div>
            <div className="stepper-button-container">
                <button className="stepper-small-button" onClick={onBack}><i className="fas fa-chevron-left" /></button>
                <button className="stepper-button" onClick={onNext} disabled={!canFinishPayment}>
                    Далее
                </button>
            </div>
        </React.Fragment>
    )
};

export default Stepper3;
