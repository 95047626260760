import * as React from 'react';
import StepperComponent from '../stepper/Stepper';

interface Reg2Props {
    onBack: () => void;
    onFinishPayment: () => void;
}

const Reg2: React.FC<Reg2Props> = ({ onBack, onFinishPayment }) => {

    const sendPaymentInfo = (subscription: any, paymentMethod: string) => {
        //send information to server
        var formData = new FormData();
        formData.append('SubscriptionCode', subscription.SubscriptionCode);
        formData.append('PaymentMethod', paymentMethod);
        formData.append('Cost', paymentMethod === 'Sberbank' ? subscription.RubCost : subscription.Cost);
        fetch('api/account/sendPaymentInfo', {
            method: 'POST',
            body: formData
        })
            .then(() => {
                onFinishPayment();
            })
            .catch(() => {
                window.Telegram.WebApp.showPopup({ message: '��������� ������ �������� ������. ����������, ���������� �����.' });
            });
    }

    return (
        <div className="reg-module-container">
            <StepperComponent onBack={onBack} onFinishPayment={sendPaymentInfo} />
        </div>
    )
};

export default Reg2;
