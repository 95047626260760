import * as React from 'react';
import { formatCost, getSubscriptionName } from '../../../../../utils';
import Autocomplete from '../../simple/Autocomplete';
import Datetimepicker from '../../simple/Datetimepicker';
import Approval from './Approval';

export interface Payment {
    id: number;
    account: {
        id: number;
        humanName: string;
    };
    paymentDateTime: string;
    subscriptionCode: string;
    paymentMethod: string;
    status: number;
    returnUntil: string;
    cost: number;
    currency: string;
}

interface PaymentApprovalsProps {
    productionMode: boolean;
}

interface AccountLookUp {
    id: number;
    displayString: string;
    class: number;
}

interface SubscriptionOption {
    label: string;
    value: string;
    cost: number;
    rubCost: number;
}

const PaymentApprovals: React.FC<PaymentApprovalsProps> = ({ productionMode }) => {

    const [payments, setPayments] = React.useState<Payment[]>([]);

    const [manualMode, setManualMode] = React.useState<boolean>(false);
    const [manualState, setManualState] = React.useState<number>(0);

    const [accounts, setAccounts] = React.useState<AccountLookUp[]>([]);

    const [selectedAccount, setSelectedAccount] = React.useState<AccountLookUp | null>(null);
    const [paymentOption, setPaymentOption] = React.useState<string>('');
    const [selectedSubscription, setSelectedSubscription] = React.useState<string>('');
    const [cost, setCost] = React.useState<number>(0);
    const [paymentDateTime, setPaymentDateTime] = React.useState<string>('');
    const [tz, setTz] = React.useState<string>('');

    const [enableCostEdit, setEnableCostEdit] = React.useState<boolean>(false);

    const subscriptionOptions: SubscriptionOption[] = [
        {
            label: 'Продление: Эксклюзив',
            value: 'EXCLUSIVE_RENEWAL',
            cost: 10000,
            rubCost: 2000
        },
        {
            label: 'Продление: Базовый',
            value: 'BASIC_RENEWAL',
            cost: 10000,
            rubCost: 2000
        },
        {
            label: 'Продление: Стандарт',
            value: 'STANDARD_RENEWAL',
            cost: 20000,
            rubCost: 4000
        },
        {
            label: 'Продление: Бизнес',
            value: 'BUSINESS_RENEWAL',
            cost: 50000,
            rubCost: 10000
        },
        {
            label: 'Продление: Бизнес Плюс',
            value: 'BUSINESS_PLUS_RENEWAL',
            cost: 500000,
            rubCost: 100000
        },
        {
            label: 'Вступление: Эксклюзив',
            value: 'EXCLUSIVE_INITIAL',
            cost: 19990,
            rubCost: 3990
        },
        {
            label: 'Вступление: Базовый',
            value: 'BASIC_INITIAL',
            cost: 100000,
            rubCost: 20000
        },
        {
            label: 'Вступление: Стандарт',
            value: 'STANDARD_INITIAL',
            cost: 480000,
            rubCost: 96000
        },
        {
            label: 'Вступление: Бизнес',
            value: 'BUSINESS_INITIAL',
            cost: 2400000,
            rubCost: 480000
        },
        {
            label: 'Апгрейд: Базовый',
            value: 'BASIC_UPGRADE',
            cost: 100000,
            rubCost: 20000
        },
        {
            label: 'Апгрейд: Стандарт',
            value: 'STANDARD_UPGRADE',
            cost: 480000,
            rubCost: 96000
        },
        {
            label: 'Апгрейд: Бизнес',
            value: 'BUSINESS_UPGRADE',
            cost: 2400000,
            rubCost: 480000
        },
        {
            label: 'Апгрейд: Бизнес Плюс',
            value: 'BUSINESS_PLUS_UPGRADE',
            cost: 7200000,
            rubCost: 1400000
        }
    ]

    const getAvailableSubscriptionsForClass = (c: number): SubscriptionOption[] => {
        if (c <= 0) { //newbie
            return subscriptionOptions.filter(s => s.value.endsWith('INITIAL'));
        }
        else {
            switch (c) {
                case 5:
                    return subscriptionOptions.filter(s =>
                        ['EXCLUSIVE_RENEWAL', 'BASIC_UPGRADE', 'STANDARD_UPGRADE', 'BUSINESS_UPGRADE', 'BUSINESS_PLUS_UPGRADE'].includes(s.value));
                case 10:
                    return subscriptionOptions.filter(s =>
                        ['BASIC_RENEWAL', 'STANDARD_UPGRADE', 'BUSINESS_UPGRADE', 'BUSINESS_PLUS_UPGRADE'].includes(s.value));
                case 20:
                    return subscriptionOptions.filter(s =>
                        ['STANDARD_RENEWAL', 'BUSINESS_UPGRADE', 'BUSINESS_PLUS_UPGRADE'].includes(s.value));
                case 30:
                    return subscriptionOptions.filter(s =>
                        ['BUSINESS_RENEWAL', 'BUSINESS_PLUS_UPGRADE'].includes(s.value));
                case 40:
                    return subscriptionOptions.filter(s =>
                        ['BUSINESS_PLUS_RENEWAL'].includes(s.value));
                default:
                    return [];
            }
        }
    }

    const selectSubscription = (subscriptionCode: string) => {
        //set selected subscription and calculate cost for it
        setSelectedSubscription(subscriptionCode);
        var subscriptionObject = subscriptionOptions.find(s => s.value === subscriptionCode);
        if (subscriptionObject != null && selectedAccount != null && paymentOption.length > 0) {
            if (!subscriptionCode.endsWith('UPGRADE')) {
                if (paymentOption === 'Sberbank') {
                    setCost(subscriptionObject.rubCost);
                }
                else {
                    setCost(subscriptionObject.cost);
                }
            }
            else { //upgrades
                var classSubscriptionCode =
                    selectedAccount.class == 5 ? 'EXCLUSIVE_INITIAL' :
                        selectedAccount.class == 10 ? 'BASIC_INITIAL' :
                            selectedAccount.class == 20 ? 'STANDARD_INITIAL' :
                                selectedAccount.class == 30 ? 'BUSINESS_INITIAL' : '';
                if (classSubscriptionCode.length > 0) {
                    var classSubscriptionObject = subscriptionOptions.find(s => s.value === classSubscriptionCode);
                    if (classSubscriptionObject != null) {
                        if (paymentOption === 'Sberbank') {
                            setCost(subscriptionObject.rubCost - classSubscriptionObject.rubCost);
                        }
                        else {
                            setCost(subscriptionObject.cost - classSubscriptionObject.cost);
                        }
                    }
                }
            }
        }
    }

    const validate = (): boolean => {
        return selectedAccount != null && selectedSubscription.length > 0 &&
            paymentOption.length > 0 && cost > 0 && paymentDateTime.length > 0 && tz.length > 0;
    }

    const createPayment = () => {
        //send information to server
        if (selectedAccount != null) {
            var formData = new FormData();
            formData.append('AccountID', selectedAccount.id.toString());
            formData.append('SubscriptionCode', selectedSubscription);
            formData.append('PaymentMethod', paymentOption);
            formData.append('Cost', cost.toString());
            formData.append('PaymentDateTime', paymentDateTime);
            formData.append('CashboxTz', tz);
            fetch('api/payment/sendPaymentInfoManual', {
                method: 'POST',
                body: formData
            })
                .then(() => {
                    setManualState(1);
                })
                .catch(() => {
                    window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
                });
        }
    }

    const paymentOptions = [
        {
            label: 'Kaspi',
            value: 'Kaspi'
        },
        {
            label: 'Visa/MC',
            value: 'VisaMC'
        },
        {
            label: 'Сбербанк',
            value: 'Sberbank'
        }
    ]

    React.useEffect(() => {
        if (manualMode) {
            fetch('/api/payment/getAccounts')
                .then(res => res.json() as Promise<AccountLookUp[]>)
                .then((data) => {
                    setAccounts(data);
                });
        }
        else {
            fetch('/api/payment/getPayments')
                .then(res => res.json() as Promise<Payment[]>)
                .then((data) => {
                    setPayments(data);
                });
        }
    }, [manualMode]);

    React.useEffect(() => {
        if (selectedSubscription.length > 0) {
            selectSubscription(selectedSubscription);
        }
    }, [paymentOption]);

    return (
        <div className="payment-approval-container">
            {!manualMode && <>
                <div className="reg-module-view-header"><b>ПЛАТЕЖИ</b></div>
                <div className="payment-approval-table-container">
                    <button className="btn btn-success btn-payment-add-manual" onClick={() => setManualMode(true)}><i className="fas fa-plus" /> Добавить вручную</button>
                    <table className="table table-striped payment-approval-table" style={{ marginTop: '8px', fontSize: '10px' }}>
                        <tbody>
                            {payments.map((payment, i) => (
                                <Approval productionMode={productionMode} payment={payment} onModify={setPayments} />
                            ))}
                        </tbody>
                    </table>
                </div>
            </>}
            {(manualMode && manualState == 0) && <>
                <div className="reg-module-view-header"><b>ДОБАВИТЬ ПЛАТЁЖ ВРУЧНУЮ</b></div>
                <div className="btn-payment-add-back" onClick={() => setManualMode(false)}><i className="fas fa-chevron-left" /></div>
                <div className="payment-approval-manual-container">
                    <div className="reg-module-block">
                        <div className="reg-module-label">Резидент (ID/Имя в Telegram/Имя и фамилия)</div>
                        <div>
                            {selectedAccount == null && <Autocomplete options={accounts.map(a => { return { label: a.displayString, value: a.id.toString() } })}
                                placeholder="Выберите Резидента" customValuesAllowed={false}
                                onValueSelected={(v) => setSelectedAccount(accounts.filter(a => a.id.toString() === v)[0])} />}
                            {selectedAccount != null && <>
                                <div style={{ display: 'inline-block', fontSize: '13px' }}><b>{selectedAccount.displayString}</b></div>
                                <button className="btn btn-primary btn-payment-edit"
                                    onClick={() => { setPaymentOption(''); setSelectedSubscription(''); setSelectedAccount(null); }}>Изм.</button>
                            </>}
                        </div>
                    </div>
                    {selectedAccount && <div className="reg-module-block">
                        <div className="reg-module-label">Способ оплаты</div>
                        <div>
                            <select style={{ 'width': '100%' }} onChange={(e) => setPaymentOption(e.target.value)}>
                                <option style={{ display: 'none' }} value="" selected></option>
                                {paymentOptions.map(po => (
                                    <option value={po.value}>{po.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>}
                    {selectedAccount && <div className="reg-module-block">
                        <div className="reg-module-label">За что оплата</div>
                        <div>
                            <select style={{ 'width': '100%' }} onChange={(e) => selectSubscription(e.target.value)}>
                                <option style={{ display: 'none' }} value="" selected></option>
                                {getAvailableSubscriptionsForClass(selectedAccount.class).map(s => (
                                    <option value={s.value}>{s.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>}
                    {paymentOption.length > 0 && <div className="reg-module-block">
                        <div className="reg-module-label">Сумма платежа</div>
                        <div>{paymentOption === 'Sberbank' ? '₽' : '₸'}
                            {enableCostEdit && <input placeholder="Введите сумму платежа" className="reg-module-input" style={{ width: '30%' }}
                                onChange={(e) => setCost(parseInt(e.target.value || '0'))} value={cost} disabled={!enableCostEdit} />}
                            {!enableCostEdit && <div style={{ display: 'inline-block' }}>{formatCost(cost)}</div>}
                            <button className="btn btn-primary btn-payment-edit" onClick={() => setEnableCostEdit(true)}>Изм.</button>
                        </div>
                    </div>}
                    <div className="reg-module-block">
                        <div className="reg-module-label">Дата и время квитанции</div>
                        <div>
                            <Datetimepicker onValueChanged={setPaymentDateTime} />
                        </div>
                    </div>
                    <div className="reg-module-block">
                        <div className="reg-module-label">Часовой пояс кассы</div>
                        <div>
                            <select style={{ 'width': '100%' }} onChange={(e) => setTz(e.target.value)}>
                                <option style={{ display: 'none' }} value="" selected></option>
                                <option value="-5">GMT+5 (Астана)</option>
                            </select>
                        </div>
                    </div>
                    <div className="reg-module-submit">
                        <button className="btn btn-success" disabled={!validate()} onClick={createPayment}>Отправить</button>
                    </div>
                </div>
            </>}
            {(manualMode && manualState == 1 && selectedAccount != null) && <>
                <div className="reg-module-view-header"><b>ДОБАВИТЬ ПЛАТЁЖ ВРУЧНУЮ</b></div>
                <div className="payment-approval-success-container">
                    <div className="payment-approval-success-message">
                        <b>Платёж успешно добавлен!</b>
                        <br />
                        <u>Параметры:</u><br />
                        Резидент: <b>{selectedAccount.displayString}</b><br />
                        Способ оплаты: <b>{paymentOption}</b><br />
                        Подписка: <b>{getSubscriptionName(selectedSubscription)}</b><br />
                        Сумма: <b>{paymentOption === 'Sberbank' ? '₽' : '₸'}{formatCost(cost)}</b><br />
                        Дата и время платежа в часовом поясе кассы: <b>{paymentDateTime}</b>
                        <br />
                        <br />
                        <div style={{ textDecoration: 'underline' }} onClick={() => {
                            setManualMode(false);
                            setManualState(0);
                            setPaymentOption(''); setSelectedSubscription(''); setSelectedAccount(null);
                            setCost(0); setPaymentDateTime(''); setTz('');
                        }}>Перейти к платежам</div>
                    </div>
                </div>
            </>}
        </div>
    )
};

export default PaymentApprovals;
