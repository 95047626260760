import React, { useState } from 'react';
import './stepper.css';
import Stepper1 from './Stepper1';
import Stepper2 from './Stepper2';
import Stepper3 from './Stepper3';
import StepperCircles from './StepperCircles';

interface StepperProps {
    onBack: () => void;
    onFinishPayment: (subscription: any, paymentMethod: string) => void;
}

const StepperComponent: React.FC<StepperProps> = ({ onBack, onFinishPayment }) => {
    const [activeStep, setActiveStep] = useState(1);
    const [selectedSubscription, setSelectedSubscription] = useState<any>(null);
    const [selectedPayment, setSelectedPayment] = useState<any>(null);

    const handleNext = () => setActiveStep((prevStep) => (prevStep < 3 ? prevStep + 1 : prevStep));
    const handleBack = () => setActiveStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));

    return (
        <div className="stepper-container">
            <StepperCircles activeStep={activeStep} />
            {activeStep == 1 && <Stepper1 onBack={onBack} onNext={(s) => { setSelectedSubscription(s); handleNext(); }} />}
            {activeStep == 2 && <Stepper2 selectedSubscription={selectedSubscription} onBack={handleBack} onNext={(p) => { setSelectedPayment(p); handleNext(); }} />}
            {activeStep == 3 && <Stepper3 paymentMethod={selectedPayment.Key} subscriptionCode={selectedSubscription.SubscriptionCode} onBack={handleBack} onNext={() => onFinishPayment(selectedSubscription, selectedPayment.Key)} />}
        </div>
    );
};

export default StepperComponent;
