import * as React from 'react';
import ReactQuill from 'react-quill';
import { Delta } from 'quill/core';
import 'react-quill/dist/quill.snow.css';

interface InputComponentProps {
    inputRef: React.RefObject<ReactQuill>;
    placeholder: string;
    onInput: (text: string) => void;
    onFocus: () => void;
    onBlur: () => void;
}

const InputComponent: React.FC<InputComponentProps> = ({ inputRef, placeholder, onInput, onFocus, onBlur }) => {

    const editorWrapperRef = React.useRef<HTMLDivElement | null>(null);

    const removeStyleAttributes = (element: HTMLDivElement) => {
        // Create a NodeIterator to traverse through the element and its descendants
        const walker = document.createTreeWalker(element, NodeFilter.SHOW_ELEMENT, null, false);

        let node;
        // Iterate through all nodes
        while (node = walker.nextNode()) {
            if (node instanceof HTMLElement) {
                node.removeAttribute('style');
            }
        }
    }

    React.useEffect(() => {
        if (inputRef.current != null) {
            inputRef.current.getEditor().on('text-change', (delta: any, oldDelta: any, source: string) => {
                if (inputRef.current != null) {
                    removeStyleAttributes(inputRef.current.getEditor().root);
                    onInput(inputRef.current.getEditor().root.innerHTML);
                }
            });

            inputRef.current.getEditor().clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
                if (delta && delta.ops) {
                    const ops = delta.ops.map((op) => ({ insert: op.insert }));
                    delta.ops = ops;
                    return delta;
                }
                else return delta;
            })
        }
    }, []);

    React.useEffect(() => {
        if (inputRef.current != null) {
            inputRef.current.getEditor().root.setAttribute('data-placeholder', placeholder);
        }
    }, [placeholder]);

    /*
    const onFocus = () => {
        if (editorWrapperRef.current != null && window.Telegram.WebApp.platform === 'ios') {
            editorWrapperRef.current.style.paddingBottom = '290px';
        }
    }

    const onBlur = () => {
        if (editorWrapperRef.current != null && window.Telegram.WebApp.platform === 'ios') {
            editorWrapperRef.current.style.paddingBottom = '';
        }
    }
    */
    return (
        <div ref={editorWrapperRef} onBlur={onBlur} onFocus={onFocus} style={{ width: '100%' }}>
            <ReactQuill ref={inputRef}
                onChange={() => { }}
                modules={{
                    toolbar: null
                }}
                placeholder={placeholder}
            />
        </div>
    );
};

export default InputComponent;
