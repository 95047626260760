import * as React from 'react';
import { formatCost } from '../../../../../utils';

interface Stepper1Props {
    onBack: () => void;
    onNext: (selectedOption: any) => void;
}

const Stepper1: React.FC<Stepper1Props> = ({ onBack, onNext }) => {
    const subscriptionOptions = [
        { Name: 'Эксклюзив', SubscriptionCode: 'EXCLUSIVE_INITIAL', Cost: 19990, RubCost: 3990, SignatureValue: '0bba982ac401b9f3e300a224ae8bc05a' },
        { Name: 'Базовый', SubscriptionCode: 'BASIC_INITIAL', Cost: 100000, RubCost: 20000, SignatureValue: '3aa2f6de1ae6a69bcb2f40102f8eaa86' },
        { Name: 'Стандарт', SubscriptionCode: 'STANDARD_INITIAL', Cost: 480000, RubCost: 96000, SignatureValue: '57827b63ad4f051085fa81c912acccf5' },
        { Name: 'Бизнес', SubscriptionCode: 'BUSINESS_INITIAL', Cost: 2400000, RubCost: 480000, SignatureValue: 'e05d469e3d66926208a10bd394628a5b' }
    ];

    const handleSubscriptionSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        const selected = subscriptionOptions.find(sub => sub.SubscriptionCode === selectedValue);
        setSelectedSubscription(selected || null);
    };

    const [selectedSubscription, setSelectedSubscription] = React.useState<any>(null);

    return (
        <React.Fragment>
            <div className="stepper-empty-space">
                <div className="stepper-select-caption">Выберите тариф</div>
                <div className="stepper-select-wrapper">
                    <select className="stepper-select" onChange={handleSubscriptionSelect} defaultValue="">
                        <option value="">Выберите тариф</option>
                        {subscriptionOptions.map((option) => (
                            <option key={option.SubscriptionCode} value={option.SubscriptionCode}>
                                {option.Name} (₸{formatCost(option.Cost)})
                            </option>
                        ))}
                    </select>
                </div>
                <div className="stepper-info-text">
                    Сумма: {selectedSubscription ? "₸" + formatCost(selectedSubscription.Cost) : '—'}
                </div>
            </div>
            <div className="stepper-button-container">
                <button className="stepper-small-button" onClick={onBack}><i className="fas fa-chevron-left" /></button>
                <button className="stepper-button" onClick={() => onNext(selectedSubscription)} disabled={!selectedSubscription}>
                    К оплате
                </button>
            </div>
        </React.Fragment>
    )
};

export default Stepper1;
