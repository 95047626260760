import { useLoading } from '../context/LoadingContext';

const useFetch = () => {
    const { setLoading } = useLoading();

    const request = async (input: RequestInfo, init?: RequestInit) => {
        setLoading(true);
        try {
            const response = await fetch(input, init);
            if (!response.ok) {
                console.error('Network response was not ok');
            }
            return response;
        } catch (error) {
            console.error('Fetch error:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    return request;
};

export default useFetch;