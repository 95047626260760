import * as React from 'react';
import { MillionaireMorningItem } from '../../store/Models';

interface LongPressMenuProps {
    visible: boolean;
    my: boolean;
    replyTask: MillionaireMorningItem | null;
    visibilityMode: number;
    onWriteReply: () => void;
    onGoToProfile: () => void;
    onStartEdit: () => void;
    onDelete: () => void;
}

const LongPressMenu: React.FC<LongPressMenuProps> = ({ visible, my, replyTask, visibilityMode, onWriteReply, onGoToProfile, onStartEdit, onDelete }) => {
    if (!visible) return null;

    const reflectionVisibilityRussian = (reflectionVisibility: number): string => {
        switch (reflectionVisibility) {
            case 0: return 'По тарифу';
            case 1: return 'Всем';
            case 2: return 'Только Администрации';
            case 3: return 'Только Ерлану';
            case 10: return 'Ерлан: Базовый и выше';
            case 20: return 'Ерлан: Стандарт и выше';
            case 30: return 'Ерлан: Бизнес и выше';
            case 40: return 'Ерлан: только Бизнес Плюс';
            case 110: return 'Всем';
            case 120: return 'Стандарт и выше';
            case 130: return 'Бизнес и выше';
            case 140: return 'Бизнес Плюс';
            case 210: return 'Только Базовый';
            case 220: return 'Только Стандарт';
            case 230: return 'Только Бизнес';
            default: return '';
        }
    };

    /*
     * go to profile
     * {visibilityMode < 100 && <div className="longpress-item" onClick={onGoToProfile}><i className="fas fa-address-card" /> Перейти в профиль</div>}
     */ 

    return (
        <div
            className="longpress-menu"
            style={{ marginTop: '5px', right: my ? '10px' : '40px' }}
        >
            {visibilityMode < 100 && <div className="longpress-item" onClick={onWriteReply}><i className="fas fa-reply" /> Ответить</div>}
            {my && <div className="longpress-item" onClick={onStartEdit}><i className="fas fa-pen" /> Редактировать</div>}
            {my && <div className="longpress-item" onClick={onDelete}><i className="fas fa-trash" /> Удалить</div>}
            {my && <div className="longpress-item-separator"></div>}
            {replyTask != null && <div className="longpress-item" onClick={() => {
                location.href = "https://t.me/c/1639867222/35691/53024";
            }}><i className="fas fa-square-arrow-up-right" /> Перейти к заданию</div>}
            <div className="longpress-item-inactive"><i className="fas fa-eye" /> {reflectionVisibilityRussian(visibilityMode)}</div>
        </div>
    );
};

export default LongPressMenu;
