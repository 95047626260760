import * as React from 'react';
import { Account } from '../../store/Models';
import { makeDateTime } from '../../utils';
import AccountAvatar from './AccountAvatar';
import IncomeSVG from './svg/IncomeSVG';
import MissedSVG from './svg/MissedSVG';
import SpendSVG from './svg/SpendSVG';

interface WalletTransactionsViewProps {
    currentUser: Account;
    onAccountClick: (account: Account) => void;
    onClose: () => void;
}

interface WalletTransaction {
    id: number;
    value: number;
    transactionDateTime: string;
    initiatorAccount: Account;
    transactionTypeCode: number;
    pointType: string | null;
}

const WalletTransactionsView: React.FC<WalletTransactionsViewProps> = ({ currentUser, onAccountClick, onClose }) => {

    const [walletTransactions, setWalletTransactions] = React.useState<WalletTransaction[]>([]);

    const getTransactionDescription = (code: number): string => {
        switch (code) {
            case 1: return 'Личная рекомендация';
            case 2: return 'Чётная продажа';
            case 3: return 'Быстрый старт';
            case 4: return 'Резидуальный (классика)';
            case 5: return 'Командный (бинар)';
            case 6: return 'Пассивный бинар';
            case 7: return 'Лидерский линейный';
            case 8: return 'Вклад в компанию';
            case 9: return 'ERA Travel';
            case 10: return 'Внутренний перевод';
            case 11: return 'Отмена операции';

            case -1: return 'Вывод средств';
            case -2: return 'Абонплата';
            case -3: return 'Абонплата (частично)';
            case -4: return 'Апгрейд тарифа';
            case -5: return 'Апгрейд тарифа (частично)';
            case -6: return 'Регистрация новичка';
            case -7: return 'Отмена регистрации';
            case -10: return 'Внутренний перевод';
            case -11: return 'Отмена операции';

            default:
                return 'Неизвестный тип операции';
        }
    }

    React.useEffect(() => {
        fetch('/api/account/getWalletTransactions')
            .then(r => r.json() as Promise<WalletTransaction[]>)
            .then(data => {
                setWalletTransactions(data);
            });
    }, []);

    return (
        <div className="wallet-transactions-view">
            <div className="wallet-transactions-view-close-btn" onClick={onClose}><i className="fas fa-chevron-left" /></div>
            <div className="wallet-transactions-view-header"><b>ИСТОРИЯ ОПЕРАЦИЙ</b></div>
            <div className="wallet-transactions-current-balance">Баланс: <b>{currentUser.balance}</b></div>
            <div className="wallet-transactions-balance-contents">
                <div className="wallet-transactions-balance-cp"><em>Доступно:</em><br /><b>{walletTransactions.filter(x => x.pointType === 'cp').reduce((accumulator, y) => { return accumulator + y.value }, 0)}</b></div>
                <div className="wallet-transactions-balance-ecp"><em>Ожидается:</em><br /><b>{walletTransactions.filter(x => x.pointType === 'ecp').reduce((accumulator, y) => { return accumulator + y.value }, 0)}</b></div>
                <div className="wallet-transactions-balance-ncp"><em>Упущено:</em><br /><b>{walletTransactions.filter(x => x.pointType === 'ncp').reduce((accumulator, y) => { return accumulator + y.value }, 0)}</b></div>
            </div>
            <div className="wallet-transactions-view-table-container">
                <table className="table">
                    <tbody>
                        {walletTransactions.map((item, i) =>
                            <React.Fragment>
                                <tr>
                                    <td rowSpan={2}>{
                                        item.pointType === 'ncp' ? <div className="missed"><MissedSVG /></div> :
                                          item.value > 0 ? <div className="income"><IncomeSVG /></div>
                                        : item.value < 0 ? <div className="spend"><SpendSVG /></div> : ''}</td>
                                    <td className="wallet-transaction-type">
                                        {getTransactionDescription(item.transactionTypeCode)}
                                    </td>
                                    <td className="wallet-transaction-value">
                                        {item.pointType === 'ncp' ?
                                            <span className="wallet-transaction-value-missed">{item.value}</span> :
                                         item.value > 0 ?
                                            <span className="wallet-transaction-value-positive">+{item.value}</span> :
                                            <b>{item.value}</b>}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div className="wallet-transaction-second-row">
                                            <div className="wallet-transaction-second-row-datetime">
                                                {makeDateTime(item.transactionDateTime)}
                                            </div>
                                            <div className="wallet-transaction-second-row-initiator">
                                                <div style={{ display: 'flex', float: 'right' }}><AccountAvatar size={19} account={item.initiatorAccount} showAsAdmin={false} classArg={null} onClick={onAccountClick} />&nbsp;<span onClick={() => { onAccountClick(item.initiatorAccount) }}>{item.initiatorAccount.name}</span></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default WalletTransactionsView;
