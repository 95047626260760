import * as React from 'react';
import { formatCost, getSubscriptionName, makeDateTime } from '../../../../../utils';
import { Payment } from './PaymentApprovals';

interface ApprovalProps {
    productionMode: boolean;
    payment: Payment;
    onModify: React.Dispatch<React.SetStateAction<Payment[]>>;
}

const Approval: React.FC<ApprovalProps> = ({ productionMode, payment, onModify }) => {
    /*
    const subscriptionOptions = [
        { SubscriptionCode: 'EXCLUSIVE_INITIAL', Cost: 19990, RubCost: 3990 },
        { SubscriptionCode: 'BASIC_INITIAL', Cost: 100000, RubCost: 20000 },
        { SubscriptionCode: 'STANDARD_INITIAL', Cost: 480000, RubCost: 96000 },
        { SubscriptionCode: 'BUSINESS_INITIAL', Cost: 2400000, RubCost: 480000 },

        { SubscriptionCode: 'EXCLUSIVE_RENEWAL', Cost: 10000, RubCost: 2000 },
        { SubscriptionCode: 'BASIC_RENEWAL', Cost: 10000, RubCost: 2000 },
        { SubscriptionCode: 'STANDARD_RENEWAL', Cost: 20000, RubCost: 4000 },
        { SubscriptionCode: 'BUSINESS_RENEWAL', Cost: 50000, RubCost: 10000 },
        { SubscriptionCode: 'BUSINESS_PLUS_RENEWAL', Cost: 500000, RubCost: 100000 }
    ];
    */

    const getCostInfo = (payment: Payment) => {
        if (payment.currency === 'RUB') {
            return "₸" + formatCost(payment.cost);
        }
        else {
            return "₽" + formatCost(payment.cost);
        }
    }

    const confirmApprove = (paymentId: string) => {
        if (!productionMode) {
            confirmApproveRequest(paymentId);
        }
        else {
            window.Telegram.WebApp.showPopup({
                title: "Подтвердите действие", message: "Вы действительно хотите подтвердить операцию?",
                buttons: [
                    { id: 'approve', type: 'default', text: "Да, подтверждаю" },
                    { id: '', type: 'default', text: "Назад" }]
            }, (id: string) => {
                if (id === 'approve') {
                    confirmApproveRequest(paymentId);
                }
            });
        }
    }

    const confirmApproveRequest = (paymentId: string) => {
        fetch('/api/payment/approve?id=' + paymentId)
            .then(() => {
                onModify(x => x.map(t => {
                    if (t.id.toString() === paymentId) {
                        t.status = 7;
                        return t;
                    }
                    else return t;
                }));
            })
            .catch(() => {
                window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
            });
    }

    const confirmDecline = (paymentId: string, subscriptionCode: string, accountId: string) => {
        if (!productionMode) {
            confirmDeclineRequest(paymentId, subscriptionCode, accountId);
        }
        else {
            window.Telegram.WebApp.showPopup({
                title: "Подтвердите действие", message: "Вы действительно хотите отклонить операцию?",
                buttons: [
                    { id: 'decline', type: 'default', text: "Да, отклоняю" },
                    { id: '', type: 'default', text: "Назад" }]
            }, (id: string) => {
                if (id === 'decline') {
                    confirmDeclineRequest(paymentId, subscriptionCode, accountId);
                }
            });
        }
    }

    const confirmDeclineRequest = (paymentId: string, subscriptionCode: string, accountId: string) => {
        fetch('/api/payment/decline?id=' + paymentId + '&subsciptionCode=' + subscriptionCode + '&accountId=' + accountId)
            .then(() => {
                onModify(x => x.map(t => {
                    if (t.id.toString() === paymentId) {
                        t.status = 100;
                        return t;
                    }
                    else return t;
                }));
            })
            .catch(() => {
                window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
            });
    }

    const confirmRevert = (paymentId: string) => {
        if (!productionMode) {
            confirmRevertRequest(paymentId);
        }
        else {
            window.Telegram.WebApp.showPopup({
                title: "Подтвердите действие", message: "Вы действительно хотите сделать возврат?",
                buttons: [
                    { id: 'revert', type: 'default', text: "Да, делаем возврат" },
                    { id: '', type: 'default', text: "Назад" }]
            }, (id: string) => {
                if (id === 'revert') {
                    confirmRevertRequest(paymentId);
                }
            });
        }
    }

    const confirmRevertRequest = (paymentId: string) => {
        fetch('/api/payment/revert?id=' + paymentId)
            .then(() => {
                onModify(x => x.map(t => {
                    if (t.id.toString() === paymentId) {
                        t.status = 200;
                        return t;
                    }
                    else return t;
                }));
            })
            .catch(() => {
                window.Telegram.WebApp.showPopup({ message: 'Произошла ошибка отправки данных. Пожалуйста, попробуйте позже.' });
            });
    }

    return (
        <tr>
            <td style={{ verticalAlign: 'middle' }}><div className={"payment-approval-status payment-approval-status-" + payment.status}></div></td>
            <td>
                <div>{payment.account.humanName}</div>
                <div>{getSubscriptionName(payment.subscriptionCode)}</div>
            </td>
            <td>
                <div>{getCostInfo(payment)} [{payment.paymentMethod}]</div>
                <div>{makeDateTime(payment.paymentDateTime)}</div>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
                <div>
                    <button className="btn-approval btn-approval-confirm" data-id={payment.id} disabled={payment.status == 7 || payment.status == 200}
                        onClick={(e) => {
                            if (e.currentTarget != null) {
                                var id = e.currentTarget.getAttribute("data-id");
                                if (id) {
                                    confirmApprove(id);
                                }
                            }
                        }}>
                        <i className="fas fa-check" /></button>
                    <button className="btn-approval btn-approval-decline" data-id={payment.id} disabled={payment.status > 0}
                        onClick={(e) => {
                            if (e.currentTarget != null) {
                                var id = e.currentTarget.getAttribute("data-id");
                                if (id) {
                                    confirmDecline(id, payment.subscriptionCode, payment.account.id.toString());
                                }
                            }
                        }}>
                        <i className="fas fa-xmark" /></button>
                    <button className="btn-approval btn-approval-revert" data-id={payment.id} disabled={payment.status != 7 || payment.subscriptionCode.endsWith('RENEWAL')}
                        onClick={(e) => {
                            if (e.currentTarget != null) {
                                var id = e.currentTarget.getAttribute("data-id");
                                if (id) {
                                    confirmRevert(id);
                                }
                            }
                        }}>
                        <i className="fas fa-rotate-left" /></button>
                </div>
            </td>
        </tr>
        )
};

export default Approval;
