import * as React from 'react';

interface StepperRenewal3Props {
    paymentMethod: string;
    subscriptionCode: string;
    onBack: () => void;
    onNext: () => void;
}

interface PaymentMethodRenewalLink {
    BUSINESS_INITIAL: string;
    STANDARD_INITIAL: string;
    BASIC_INITIAL: string;

    BUSINESS_PLUS_RENEWAL: string;
    BUSINESS_RENEWAL: string;
    STANDARD_RENEWAL: string;
    BASIC_RENEWAL: string;
    EXCLUSIVE_RENEWAL: string;
}

interface ServiceWALink {
    Kaspi: PaymentMethodRenewalLink;
    VisaMC: PaymentMethodRenewalLink;
    Sberbank: PaymentMethodRenewalLink;
}

const StepperRenewal3: React.FC<StepperRenewal3Props> = ({ paymentMethod, subscriptionCode, onBack, onNext }) => {
    const delay = 90;

    const [canFinishPayment, setCanFinishPayment] = React.useState<boolean>(false);

    const serviceWALinks: ServiceWALink = {
        "Kaspi": {
            "BUSINESS_INITIAL": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Бизнес» стоимостью ₸2,400,000 через Kaspi. Квитанцию прилагаю.",
            "STANDARD_INITIAL": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт» стоимостью ₸480,000 через Kaspi. Квитанцию прилагаю.",
            "BASIC_INITIAL": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Базовый» стоимостью ₸100,000 через Kaspi. Квитанцию прилагаю.",

            "BUSINESS_PLUS_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Бизнес Плюс» стоимостью ₸500,000 через Kaspi. Квитанцию прилагаю.",
            "BUSINESS_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Бизнес» стоимостью ₸50,000 через Kaspi. Квитанцию прилагаю.",
            "STANDARD_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Стандарт» стоимостью ₸20,000 через Kaspi. Квитанцию прилагаю.",
            "BASIC_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Базовый» стоимостью ₸10,000 через Kaspi. Квитанцию прилагаю.",
            "EXCLUSIVE_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Эксклюзив» стоимостью ₸10,000 через Kaspi. Квитанцию прилагаю."
        },
        "VisaMC": {
            "BUSINESS_INITIAL": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Бизнес» стоимостью ₸2,400,000 картой Visa/MC. Квитанцию прилагаю.",
            "STANDARD_INITIAL": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт» стоимостью ₸480,000 картой Visa/MC. Квитанцию прилагаю.",
            "BASIC_INITIAL": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Базовый» стоимостью ₸100,000 через картой Visa/MC. Квитанцию прилагаю.",

            "BUSINESS_PLUS_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Бизнес Плюс» стоимостью ₸500,000 картой Visa/MC. Квитанцию прилагаю.",
            "BUSINESS_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Бизнес» стоимостью ₸50,000 картой Visa/MC. Квитанцию прилагаю.",
            "STANDARD_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Стандарт» стоимостью ₸20,000 картой Visa/MC. Квитанцию прилагаю.",
            "BASIC_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Базовый» стоимостью ₸10,000 картой Visa/MC. Квитанцию прилагаю.",
            "EXCLUSIVE_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Эксклюзив» стоимостью ₸10,000 картой Visa/MC. Квитанцию прилагаю."
        },
        "Sberbank": {
            "BUSINESS_INITIAL": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Бизнес» стоимостью ₽480,000 переводом на Сбербанк России. Квитанцию прилагаю.",
            "STANDARD_INITIAL": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Стандарт» стоимостью ₽96,000 переводом на Сбербанк России. Квитанцию прилагаю.",
            "BASIC_INITIAL": "Здравствуйте! Я оплатил(-а) апгрейд в Клубе MillionERA на тариф «Базовый» стоимостью ₽20,000 переводом на Сбербанк России. Квитанцию прилагаю.",

            "BUSINESS_PLUS_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Бизнес Плюс» стоимостью ₽100,000 переводом на Сбербанк России. Квитанцию прилагаю.",
            "BUSINESS_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Бизнес» стоимостью ₽10,000 переводом на Сбербанк России. Квитанцию прилагаю.",
            "STANDARD_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Стандарт» стоимостью ₽4,000 переводом на Сбербанк России. Квитанцию прилагаю.",
            "BASIC_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Базовый» стоимостью ₽2,000 переводом на Сбербанк России. Квитанцию прилагаю.",
            "EXCLUSIVE_RENEWAL": "Здравствуйте! Я оплатил(-а) продление в Клубе MillionERA на тарифе «Эксклюзив» стоимостью ₽2,000 переводом на Сбербанк России. Квитанцию прилагаю."
        }
    }

    const getServiceWALink = (paymentMethod: string, subscriptionCode: string): string => {
        return "https://wa.me/77052042585?text=" + serviceWALinks[paymentMethod as keyof ServiceWALink][subscriptionCode as keyof PaymentMethodRenewalLink];
    }

    return (
        <React.Fragment>
            <div className="stepper-empty-space">
                <div className="stepper-info-text">Отправьте квитанцию</div>
                <div>в <a target="_blank" href={getServiceWALink(paymentMethod, subscriptionCode)} onClick={() => {
                    window.setTimeout(() => setCanFinishPayment(true), delay * 1000);
                }} >Службу Заботы</a></div>
            </div>
            <div className="stepper-button-container">
                <button className="stepper-small-button" onClick={onBack}><i className="fas fa-chevron-left" /></button>
                <button className="stepper-button" onClick={onNext} disabled={!canFinishPayment}>
                    Далее
                </button>
            </div>
        </React.Fragment>
    )
};

export default StepperRenewal3;
