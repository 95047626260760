import * as React from 'react';

interface ChallengeContextMenuProps {
    visible: boolean;
    onSelectMode: (mode: number) => void;
    basicChallengeUpdates: boolean;
    commandChallengeUpdates: boolean;
    coinChallengeUpdates: boolean;
}

const ChallengeContextMenu: React.FC<ChallengeContextMenuProps> = ({ visible, onSelectMode, basicChallengeUpdates, commandChallengeUpdates, coinChallengeUpdates }) => {
    if (!visible) return null;

    /*
     * <div className="longpress-item" onClick={() => onSelectMode(1)}>Личные приглашения</div>
       <div className="longpress-item" onClick={() => onSelectMode(2)}>Командный челлендж</div>
     * 
     */ 

    return (
        <div className="longpress-menu longpress-menu-bordered" style={{ bottom: '60px', right: '17px' }}>
            <div className="longpress-item" onClick={() => onSelectMode(0)}>
                Командный челлендж {commandChallengeUpdates && <span className="longpress-item-has-updates">&nbsp;</span>}
            </div>
            <div className="longpress-item" onClick={() => onSelectMode(2)}>
                Челлендж Октября {basicChallengeUpdates && <span className="longpress-item-has-updates"></span>}
            </div>
            <div className="longpress-item" onClick={() => onSelectMode(3)}><div>Рейтинг по уровням</div></div>
            <div className="longpress-item" onClick={() => onSelectMode(5)}><div>#ERAТворитДобро</div></div>
            <div className="longpress-item" onClick={() => onSelectMode(4)}>
                Рейтинг по ERA Coin {coinChallengeUpdates && <span className="longpress-item-has-updates"></span>}
            </div>
        </div>
    );
};

export default ChallengeContextMenu;
