import * as React from 'react';
import { Account } from '../../store/Models';

interface SendMenuProps {
    visible: boolean;
    state: string;
    currentMode: number;
    currentUser: Account;
    editorWrapperRef: React.RefObject<HTMLDivElement>;
    onSelectMode: (mode: number) => void;
    onClose: () => void;
}

const SendMenu: React.FC<SendMenuProps> = ({ visible, state, currentMode, currentUser, editorWrapperRef, onSelectMode, onClose }) => {
    if (!visible) return null;

    var bottom = 30;

    if (editorWrapperRef.current != null) {
        bottom = 30 + parseInt(editorWrapperRef.current.style.paddingBottom || '0');
    }

    var founderAccountId = 1000029;

    const appState = {
        MillionaireMorning: 'MILLIONAIRE_MORNING',
        Streams: 'STREAMS',
        Reflections: 'REFLECTIONS',
        Challenge: 'CHALLENGE',
        AccountProfile: 'ACCOUNT_PROFILE',
        Admin: 'ADMIN',
        Test: 'TEST',
        Reg0: 'REG0',
        Reg1: 'REG1',
        Reg2: 'REG2',
        RegDeny: 'DENY',
        Renewal: 'RENEWAL'
    }

    return (
        <div
            className="longpress-menu"
            style={{ bottom: bottom, right: 25 }}
            onClick={onClose}
        >
            {currentUser.id !== founderAccountId && <>
                {currentUser.isAdmin == 0 && <div>
                    <div style={{ 'background': '#ccc', 'fontWeight': 'bold' }} className="longpress-item-inactive">Видимость:</div>
                    <div className="longpress-item-separator"></div>
                    <div className="longpress-item" onClick={() => { onSelectMode(1) }}>{currentMode == 1 ? <i className="fas fa-check" /> : ""} Всем</div>
                    <div className="longpress-item" onClick={() => { onSelectMode(0) }}>{currentMode == 0 ? <i className="fas fa-check" /> : ""} Моему тарифу и выше</div>
                    <div className="longpress-item" onClick={() => { onSelectMode(2) }}>{currentMode == 2 ? <i className="fas fa-check" /> : ""} Только Администрации</div>
                    {currentUser.class == 40 && <div className="longpress-item" onClick={() => { onSelectMode(3) }}>{currentMode == 3 ? <i className="fas fa-check" /> : ""} Только Ерлану</div>}
                </div>
                }
                {(currentUser.isAdmin > 0 && state === appState.Streams) && <div>
                    <div style={{ 'background': '#ccc', 'fontWeight': 'bold' }} className="longpress-item-inactive">Отправить:</div>
                    <div className="longpress-item-separator"></div>
                    <div className="longpress-item" onClick={() => { onSelectMode(110) }}>{currentMode == 110 ? <i className="fas fa-check" /> : ""} Всем от Администрации</div>
                    <div className="longpress-item" onClick={() => { onSelectMode(210) }}>{currentMode == 210 ? <i className="fas fa-check" /> : ""} Только Базовый от Администрации</div>
                    <div className="longpress-item" onClick={() => { onSelectMode(120) }}>{currentMode == 120 ? <i className="fas fa-check" /> : ""} Стандарт и выше от Администрации</div>
                    <div className="longpress-item" onClick={() => { onSelectMode(220) }}>{currentMode == 220 ? <i className="fas fa-check" /> : ""} Только Стандарт от Администрации</div>
                    <div className="longpress-item" onClick={() => { onSelectMode(130) }}>{currentMode == 130 ? <i className="fas fa-check" /> : ""} Бизнес и выше от Администрации</div>
                    <div className="longpress-item" onClick={() => { onSelectMode(230) }}>{currentMode == 230 ? <i className="fas fa-check" /> : ""} Только Бизнес от Администрации</div>
                    <div className="longpress-item" onClick={() => { onSelectMode(140) }}>{currentMode == 140 ? <i className="fas fa-check" /> : ""} Бизнес Плюс от Администрации</div>
                </div>
                }
            </>}
            {(currentUser.id === founderAccountId && state !== appState.Streams) && <div>
                <div style={{ 'background': '#ccc', 'fontWeight': 'bold' }} className="longpress-item-inactive">Отправить:</div>
                <div className="longpress-item-separator"></div>
                <div className="longpress-item" onClick={() => { onSelectMode(1) }}>{currentMode == 1 ? <i className="fas fa-check" /> : ""} Ерлан: Всем</div>
                <div className="longpress-item" onClick={() => { onSelectMode(10) }}>{currentMode == 10 ? <i className="fas fa-check" /> : ""} Ерлан: Базовый и выше</div>
                <div className="longpress-item" onClick={() => { onSelectMode(20) }}>{currentMode == 20 ? <i className="fas fa-check" /> : ""} Ерлан: Стандарт и выше</div>
                <div className="longpress-item" onClick={() => { onSelectMode(30) }}>{currentMode == 30 ? <i className="fas fa-check" /> : ""} Ерлан: Бизнес и выше</div>
                <div className="longpress-item" onClick={() => { onSelectMode(40) }}>{currentMode == 40 ? <i className="fas fa-check" /> : ""} Ерлан: только Бизнес Плюс</div>
            </div>
            }
        </div>
    );
};

export default SendMenu;
